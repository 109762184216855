import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/globals/layout'
import Seo from '../components/globals/seo'
import Hero from '../components/hero'
import Container from '../components/globals/container'
import BlogDetail from '../components/blog-detail'
import Share from '../components/share'
import TagsList from '../components/tags-list'
import LatestPostsVertical from '../components/latest-posts-vertical'
import FeaturedPosts from '../components/featured-posts'

const BlogPostTemplate = ({ data }) => {
  const { post, previous, next, latest, related } = data
  const url = typeof window !== 'undefined' ? window.location.href : ''

  const relatedPosts = related.nodes.sort(() => 0.5 - Math.random()).slice(0, 3)

  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.body.childMdx.excerpt}
        image={`http:${post.heroImage.file.url}?w=1200&h=630`}
      />
      <Hero
        title={post.category.name}
        backgroundImage={post.category.backgroundImage}
      />
      <Main>
        <Blogpost>
          <BlogDetail data={post} />
          <Pagination>
            <div>
              {!!previous && (
                <Link to={`/${previous.slug}`}>
                  <span>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </span>
                  {previous.title}
                </Link>
              )}
            </div>
            <div>
              {!!next && (
                <Link to={`/${next.slug}`}>
                  {next.title}
                  <span>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                </Link>
              )}
            </div>
          </Pagination>
        </Blogpost>

        <Sidebar>
          <Share
            url={url}
            title={post.title}
            description={post.body.childMdx.excerpt}
            image={post.heroImage.file.url}
          />
          {!!latest && <LatestPostsVertical posts={latest} />}
          {!!post.tags && <TagsList tags={post.tags} />}
        </Sidebar>
      </Main>
      {!!relatedPosts && (
        <FeaturedPosts posts={relatedPosts} title="Artículos relacionados" />
      )}
    </Layout>
  )
}

const Main = styled(Container)`
  max-width: ${props => props.theme.containerSmallMaxWidth};
  margin-bottom: 120px;

  ${props => props.theme.mq.tabletLandscape} {
    display: flex;
  }

  ${props => props.theme.mq.desktop} {
    margin-bottom: 90px;
  }
`

const Blogpost = styled.div`
  ${props => props.theme.mq.tabletLandscape} {
    width: calc(100% - 372px);
  }
`

const Sidebar = styled.div`
  ${props => props.theme.mq.tabletLandscape} {
    width: 342px;
    margin-left: 30px;
    flex-shrink: 0;
  }
`

const Pagination = styled.div`
  display: flex;
  margin: 0 -15px;

  ${props => props.theme.mq.until.tabletLandscape} {
    margin-bottom: 60px;
  }

  & > div {
    flex-shrink: 0;
    width: 50%;

    &:last-child {
      a {
        text-align: right;
        justify-content: flex-end;

        span {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: 0 15px;
    flex-shrink: 0;
    font-family: ${props => props.theme.seccondaryFont};
    font-weight: 700;
    font-size: 16px;
    color: ${props => props.theme.colors.seccondaryTextColor};
    line-height: 20px;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    ${props => props.theme.mq.tablet} {
      font-size: 20px;
    }

    &:hover {
      color: ${props => props.theme.colors.eduRed};

      span {
        background-color: ${props => props.theme.colors.eduRed};
        color: #fff;
      }
    }

    span {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      margin-right: 20px;
      margin-left: 0;
      border-radius: 50%;
      border: ${props => `2px dashed ${props.theme.colors.eduRed}`};
      color: ${props => props.theme.colors.eduRed};
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

      ${props => props.theme.mq.tablet} {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export default BlogPostTemplate

export const pageQuery = graphql`
  query (
    $slug: String!
    $categorySlug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      publishDate(formatString: "MMMM, DD YYYY", locale: "es_EC")
      heroImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 985
          quality: 60
        )
        file {
          url
        }
      }
      body {
        childMdx {
          body
          excerpt
          timeToRead
        }
        childMarkdownRemark {
          html
        }
      }
      category {
        name
        backgroundImage {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 2400
            height: 800
            quality: 100
          )
        }
      }
      tags {
        name
        slug
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
    latest: allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 5
      filter: { slug: { ne: $slug } }
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM, DD YYYY", locale: "es_EC")
        heroImage {
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 150
            height: 150
          )
        }
      }
    }
    related: allContentfulBlogPost(
      filter: { category: { slug: { eq: $categorySlug } }, slug: { ne: $slug } }
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM, DD YYYY", locale: "es_EC")
        heroImage {
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 150
            height: 150
          )
        }
      }
    }
  }
`
