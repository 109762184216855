import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Container from './globals/container'
import BlogCard from './blog-card'

const FeaturedPosts = ({ title, posts, isHome }) => {
  return (
    <>
      {isHome && (
        <DecoImage>
          <StaticImage alt="Decoration" src="../images/top-kids.png" />
        </DecoImage>
      )}
      <Wrapper isHome={isHome}>
        <Inner>
          {!!title && <Title>{title}</Title>}
          <FlyingIconLeft>
            <StaticImage
              alt="Decoration"
              src="../images/flying-icons/flying-icon6.png"
              placeholder="none"
            />
          </FlyingIconLeft>
          <Grid>
            {posts.map(post => (
              <BlogCard key={post.slug} {...post} />
            ))}
          </Grid>
          <FlyingIconRight>
            <StaticImage
              alt="Decoration"
              src="../images/flying-icons/flying-icon5.png"
              placeholder="none"
            />
          </FlyingIconRight>
        </Inner>
      </Wrapper>
    </>
  )
}

const DecoImage = styled(Container)`
  margin-bottom: -60px;

  ${props => props.theme.mq.tablet} {
    margin-bottom: -100px;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding-top: ${props => (props.isHome ? '120px' : '60px')};
  padding-bottom: ${props => (props.isHome ? '120px' : '60px')};

  ${props => props.theme.mq.desktop} {
    padding-top: 120px;
    padding-bottom: 160px;
  }
`

const Inner = styled(Container)`
  position: relative;
  max-width: ${props => props.theme.containerSmallMaxWidth};
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
`

const FlyingIconLeft = styled.div`
  position: absolute;
  top: 10%;
  left: -6%;

  ${props => props.theme.mq.until.desktop} {
    display: none;
  }
`

const FlyingIconRight = styled.div`
  position: absolute;
  right: -6%;
  bottom: -10%;

  ${props => props.theme.mq.until.desktop} {
    display: none;
  }
`

const Grid = styled.div`
  position: relative;
  z-index: 1;

  > * {
    & + * {
      margin-top: 20px;
    }
  }

  ${props => props.theme.mq.tablet} {
    display: flex;
    justify-content: center;
    margin: 0 -8px;

    > * {
      width: calc(33.33% - 16px);
      margin: 0 8px;
    }
  }

  ${props => props.theme.mq.tabletLandscape} {
    margin: 0 -15px;

    > * {
      width: calc(33.33% - 30px);
      margin: 0 15px;
    }
  }
`

export default FeaturedPosts
