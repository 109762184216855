import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  PinterestShareButton
} from 'react-share'

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  faPinterest,
  faFacebook,
  faTwitter,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'

const Share = ({ url, title, description, image }) => (
  <Wrapper>
    <Title>Compartir</Title>
    <List>
      <FacebookShareButton url={url} quote={description}>
        <Button color="#1877f2">
          <FontAwesomeIcon icon={faFacebook} />
        </Button>
      </FacebookShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        summary={description}
        source="www.edukapuli.com"
      >
        <Button color="#0a66c2">
          <FontAwesomeIcon icon={faLinkedin} />
        </Button>
      </LinkedinShareButton>

      <TwitterShareButton url={url} title={title}>
        <Button color="#1da1f2">
          <FontAwesomeIcon icon={faTwitter} />
        </Button>
      </TwitterShareButton>

      <PinterestShareButton url={url} media={image} description={description}>
        <Button color="#bd081c">
          <FontAwesomeIcon icon={faPinterest} />
        </Button>
      </PinterestShareButton>

      <EmailShareButton url={url} subject={title} body={description}>
        <Button>
          <FontAwesomeIcon icon={faEnvelope} />
        </Button>
      </EmailShareButton>
    </List>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-bottom: 60px;
`

const Title = styled.h2`
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 30px;
`

const List = styled.div`
  margin: -5px;

  button {
    margin: 5px;
  }
`

const Button = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  font-family: ${props => props.theme.seccondaryFont};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  background-color: ${props =>
    props.color ? props.color : props.theme.colors.eduOrange};
  border: 0;
  border-radius: 50%;
  box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`

export default Share
