import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const LatestPostsVertical = ({ posts }) => {
  return (
    <Wrapper>
      <Title>Artículos Recientes</Title>
      <List>
        {posts.nodes.map(post => (
          <li key={post.slug}>
            <Post to={`/${post.slug}`}>
              <GatsbyImage
                alt={post.heroImage.description}
                image={post.heroImage.gatsbyImageData}
              />
              <div>
                <PostTitle>{post.title}</PostTitle>
                <PostDate>{post.publishDate}</PostDate>
              </div>
            </Post>
          </li>
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 60px;
`

const Title = styled.h2`
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 30px;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    & + li {
      margin-top: 20px;
    }
  }
`

const Post = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  .gatsby-image-wrapper {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    border-radius: 50%;
    flex-shrink: 0;
  }
`

const PostTitle = styled.h3`
  font-size: 18px;
  line-height: 20px;
  margin: 0;
`

const PostDate = styled.p`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
`

export default LatestPostsVertical
