import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'

import pattern from '../images/light-pattern.png'

const BlogDetail = ({ data }) => (
  <Wrapper>
    {console.log(data)}
    <ImageContainer>
      <GatsbyImage
        alt={data.heroImage.description}
        image={data.heroImage.gatsbyImageData}
      />
      <InfoWrapper>
        <Info>
          <span>
            <FontAwesomeIcon icon={faCalendarDay} />
            {data.publishDate}
          </span>
          <span>
            {data.body.childMdx.timeToRead} minuto
            {data.body.childMdx.timeToRead > 1 ? 's' : ''} de lectura
          </span>
        </Info>
      </InfoWrapper>
    </ImageContainer>
    <ContentContainer>
      <h1>{data.title}</h1>
      <Author>por {data.author.name}</Author>
      {false && <MDXRenderer>{data.body.childMdx.body}</MDXRenderer>}
      <div
        dangerouslySetInnerHTML={{
          __html: data.body.childMarkdownRemark.html
        }}
      />
    </ContentContainer>
  </Wrapper>
)

const Wrapper = styled.article`
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.colors.background};
  margin-top: ${props => (props.isHome ? '60px' : 0)};
  margin-bottom: 60px;

  ${props => props.theme.mq.desktop} {
    margin-top: ${props => (props.isHome ? '100px' : 0)};
    margin-bottom: 100px;
  }
`

const ImageContainer = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 13px;
    background-image: url(${pattern});
  }
`

const InfoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  z-index: 1;
  padding: 0 20px;
  transform: translateY(50%);
  width: 100%;
`

const Info = styled.div`
  background: ${props => props.theme.colors.eduBlue};
  border-radius: 6px;
  display: inline-block;
  padding: 10px 20px;
  box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;

  ${props => props.theme.mq.tabletLandscape} {
    font-size: 18px;
  }

  svg {
    margin-right: 10px;
  }

  span {
    & + span {
      position: relative;
      margin-left: 10px;
      padding-left: 15px;

      &:before {
        width: 4px;
        height: 4px;
        position: absolute;
        border-radius: 50%;
        content: '';
        top: 10px;
        left: 0;
        background: #fff;
      }
    }
  }
`

const Author = styled.p`
  font-weight: 700;
  margin: 0 0 40px;
`

const ContentContainer = styled.div`
  padding: 60px 30px 30px 25px;
  color: ${props => props.theme.colors.seccondaryTextColor};

  h1 {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 15px;

    ${props => props.theme.mq.desktop} {
      font-size: 65px;
      line-height: 75px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  iframe {
    width: 100%;
  }
`

export default BlogDetail
