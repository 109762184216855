import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Container from './globals/container'

import pattern from '../images/white-pattern.png'

// markup
const Hero = ({ title, backgroundImage, isHeading }) => (
  <Wrapper>
    <Inner>
      <Box>{isHeading ? <h1>{title}</h1> : <p>{title}</p>}</Box>
    </Inner>
    {backgroundImage && (
      <GatsbyImage
        alt={backgroundImage.description}
        image={backgroundImage.gatsbyImageData}
      />
    )}
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 300px;
  margin-bottom: 60px;
  padding-top: 50px;

  ${props => props.theme.mq.desktop} {
    height: 500px;
    margin-bottom: 100px;
    padding-top: 100px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 12%,
      rgba(255, 255, 255, 0) 40%
    );
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 13px;
    background-image: url(${pattern});
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
`

const Inner = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  max-width: 900px;
  padding: 30px 40px;
  background-color: rgba(256, 256, 256, 0.8);
  border-radius: 14px;
  text-align: center;

  h1,
  p {
    font-family: ${props => props.theme.seccondaryFont};
    font-size: 38px;
    font-weight: 800;
    line-height: 1;
    color: ${props => props.theme.colors.primaryTextColor};
    margin: 0;

    ${props => props.theme.mq.desktop} {
      font-size: 52px;
    }
  }
`

export default Hero
