import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const TagsList = ({ tags }) => {
  const colors = [
    'eduRed',
    'eduBlue',
    'eduGreen',
    'eduYellow',
    'eduPurple',
    'eduOrange'
  ]

  return (
    <Wrapper>
      <Title>Etiquetas</Title>
      <List>
        {tags.map((tag, i) => (
          <li key={tag.name}>
            <Tag to={`/tag/${tag.slug}`} color={colors[i]}>
              {tag.name}
            </Tag>
          </li>
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 60px;

  ${props => props.theme.mq.desktop} {
    margin-bottom: 40px;
  }
`

const Title = styled.h2`
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 30px;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -5px;
  padding: 0;

  li {
    margin: 5px;
  }
`

const Tag = styled(Link)`
  display: block;
  padding: 10px 20px;
  font-family: ${props => props.theme.seccondaryFont};
  font-size: 15px !important;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  background-color: ${props => props.theme.colors[props.color]};
  box-shadow: inset 0px -4px 0px 0px rgb(0 0 0 / 10%);
  border-radius: 6px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
`

export default TagsList
